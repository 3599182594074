import { Box, Card, IconButton, NativeSelect, Typography } from "@mui/material";
import EditTools from "./EditTools";
import { useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material/Select"; // Import SelectChangeEvent
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  Button,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import DynamicProp from "../../util/DynamicProp";
import ERP from "../../util/ERP";
import { notificationManager } from "../../managers/NotificationManager";

type FieldName =
  | "num"
  | "qty"
  | "desc"
  | "resource"
  | "certified"
  | "firstArticle"
  | "totalComplete"
  | "qtyQueuedNextOp"
  | "breakDownComplete";
type Data = { num: number; item: string; qty: number }[];

export type PartOperationsProps = {
  partId: string;
  readOnly?: boolean;
};
export default function BOM(props: PartOperationsProps) {
  const [isEditing, setEditMode] = useState(false);
  const [tableData, setTableData] = useState<Data>([]);
  const [editIdx, setEditIdx] = useState(-1); // Track the row being edited
  const [editCount, setEditCount] = useState(0);

  useEffect(() => {
    const getOps = () => {
      ERP.GetObject("part", props.partId).then((data) => {
        setTableData(((data as unknown as { bom?: [] }).bom as Data) || []);
      });
    };

    getOps();
  }, []);

  // Handle input change for editable fields
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: FieldName,
    index: number
  ) => {
    const newData = [...tableData];
    newData[index] = { ...newData[index], [field]: e.target.value }; // Update the relevant field
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleSelectChange = (
    index: number,
    fieldName: string,
    value: string
  ) => {
    const newData = [...tableData];
    newData[index] = { ...newData[index], [fieldName]: value };
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  // row arrangement
  const handleMoveUp = (index: number) => {
    const newData = [...tableData];
    [newData[index - 1], newData[index]] = [newData[index], newData[index - 1]];
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleMoveDown = (index: number) => {
    const newData = [...tableData];
    [newData[index + 1], newData[index]] = [newData[index], newData[index + 1]];
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleDeleteRow = (index: number) => {
    const newData = tableData.filter((_, i) => i !== index);
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  // Add new blank operation
  const handleAddNewOperation = () => {
    const newOperation = {
      num: tableData.length + 1, // Set OP# to next available number
      item: "",
      qty: 0,
    };

    setTableData([...tableData, newOperation]);
    setEditIdx(tableData.length); // Set the newly added row to be editable
    setEditCount(editCount + 1);
  };

  function handleToggleEditMode(editing: boolean) {
    setEditMode(editing);
  }
  function handleSave() {
    if (editCount > 0) {
      const parsedData = tableData;
      ERP.UpdateObject("part", props.partId, [
        { name: "bom", value: parsedData as unknown as string },
      ])
        .then(() => {
          notificationManager.notify(
            "success",
            `Saved changes. (${editCount})`
          );
          setEditCount(0);
        })
        .catch((err) =>
          notificationManager.notify("error", `Failed to save changes: ${err}`)
        );
      setEditMode(false);
    }
  }
  return (
    <Card sx={{ width: "100%", marginTop: 2 }}>
      <Box display="flex" flexDirection="row" justifyContent={"space-between"}>
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            verticalAlign: "bottom",
          }}
        >
          Bill Of Materials
        </Typography>
        {!props.readOnly && (
          <EditTools
            isEditing={isEditing}
            toggleEdit={handleToggleEditMode}
            onSave={handleSave}
          />
        )}
      </Box>
      <br />
      <TableContainer component={Paper} sx={{}}>
        <Table size="small" sx={{}}>
          <TableHead>
            <TableRow
              sx={{
                borderBottom: "2px solid rgba(0, 0, 0, 0.87)", // Adjust the thickness and color here
              }}
            >
              <TableCell>Ln. #</TableCell>
              <TableCell>Qty.</TableCell>
              <TableCell>Item</TableCell>
              {isEditing && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.map((row, index) => (
                <TableRow key={index}>
                  {/* Editable OP# Cell */}
                  <TableCell>{row.num}</TableCell>

                  <TableCell>
                    {isEditing ? (
                      <TextField
                        type="number"
                        value={row.qty}
                        onChange={(e) => handleChange(e, "qty", index)}
                      />
                    ) : (
                      row.qty
                    )}
                  </TableCell>

                  {/* Editable Resource Dropdown */}
                  <TableCell>
                    <DynamicProp
                      value={row.item}
                      name="part&cots"
                      editMode={isEditing}
                      condensed
                      onEdit={(newValue) => {
                        handleSelectChange(index, "item", newValue as string);
                      }}
                    />
                  </TableCell>
                  {isEditing && (
                    <TableCell>
                      <IconButton
                        onClick={() => handleMoveUp(index)}
                        disabled={index === 0}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleMoveDown(index)}
                        disabled={index === tableData.length - 1}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteRow(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isEditing && (
        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          onClick={handleAddNewOperation}
        >
          Add New Item
        </Button>
      )}
    </Card>
  );
}
