import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ProfileSelect from "./Profile/ProfileSelect";

export default function Search() {
  return (
    <FormControl sx={{ width: { xs: "100%", md: "20ch" } }} variant="outlined">
      <ProfileSelect />
    </FormControl>
  );
}
