import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Card,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ERP from "../../util/ERP";
import { User } from "../../util/Typings";
import { notificationManager } from "../../managers/NotificationManager";

export default function PreferenceMenu() {
  const [profiles, setProfiles] = useState<string[]>([]);
  const [newProfile, setNewProfile] = useState<string>("");

  useEffect(() => {
    const getProfiles = () => {
      ERP.GetObject<User>("user", ERP.LocalUser.ID()).then((user) => {
        setProfiles(user.preferences || []);
      });
    };

    getProfiles();
  }, []);

  const handleAddProfile = () => {
    if (!newProfile.trim()) return;
    const updatedProfiles = [...profiles, newProfile.trim()];
    setProfiles(updatedProfiles);
    console.log(profiles);
    ERP.UpdateObject("user", ERP.LocalUser.ID(), [
      {
        name: "preferences",
        value: updatedProfiles as unknown as string,
      },
    ])
      .then((value) => {
        notificationManager.notify("success", "Profiles updated successfully.");
      })
      .catch((err) => notificationManager.notify("error", err));

    setNewProfile("");
  };

  const handleDeleteProfile = (index: number) => {
    const updatedProfiles = profiles.filter((_, i) => i !== index);
    setProfiles(updatedProfiles);
    ERP.UpdateObject("user", ERP.LocalUser.ID(), [
      {
        name: "preferences",
        value: updatedProfiles as unknown as string,
      },
    ])
      .then((value) => {
        notificationManager.notify("success", "Profiles updated successfully.");
      })
      .catch((err) => notificationManager.notify("error", err));
  };

  return (
    <Card>
      <Box sx={{ maxWidth: "100%", margin: "auto" }}>
        {/* Header */}
        <Typography variant="h6" align="left" sx={{ mb: 2 }}>
          Preference Profiles
        </Typography>

        {/* Profiles List */}
        <List>
          {profiles.map((profile, index) => (
            <Card
              sx={{
                mb: 1,
              }}
            >
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteProfile(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={profile} />
              </ListItem>
            </Card>
          ))}
        </List>

        {/* Add Profile Section (moved below the list) */}
        <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
          <TextField
            label="Profile Name"
            variant="outlined"
            value={newProfile}
            onChange={(e) => setNewProfile(e.target.value)}
            fullWidth
          />
          <Button variant="contained" onClick={handleAddProfile}>
            Add
          </Button>
        </Box>
      </Box>
    </Card>
  );
}
