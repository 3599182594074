export const columnsData = [
  {
    field: "critical",
    headerName: "Criticality",
    flex: 1,
    editable: true,
    type: "number",
    minWidth: 100, // Minimum size
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    editable: true,
    type: "singleSelect", // Set the type explicitly
    minWidth: 100, // Minimum size
    options: ["Complete", "Machining", "Mach. Complete"],
  },
  {
    field: "pn",
    headerName: "P/N",
    flex: 1,
    editable: true,
    type: "string", // Set the type explicitly
    minWidth: 100, // Minimum size
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    editable: true,
    type: "string", // Set the type explicitly
    minWidth: 150, // Minimum size
  },
  {
    field: "qty",
    headerName: "Qty",
    flex: 1,
    editable: true,
    type: "number", // Set the type explicitly
    minWidth: 80, // Minimum size
  },
  {
    field: "material",
    headerName: "Material",
    flex: 1,
    editable: true,
    type: "string", // Set the type explicitly
    minWidth: 120, // Minimum size
  },
  {
    field: "materialProvided",
    headerName: "Mat. Provided",
    flex: 1,
    editable: true,
    type: "boolean", // Set the type explicitly
    minWidth: 50, // Minimum size
  },
  {
    field: "materialStatus",
    headerName: "Mat. Status",
    flex: 1,
    editable: true,
    type: "boolean", // Set the type explicitly
    minWidth: 50, // Minimum size
  },
  {
    field: "finishing",
    headerName: "Finishing",
    flex: 1,
    editable: true,
    type: "string", // Set the type explicitly
    minWidth: 120, // Minimum size
  },
  {
    field: "roughPoCost",
    headerName: "Rough PO Cost",
    flex: 1,
    editable: true,
    type: "number", // Set the type explicitly
    minWidth: 120, // Minimum size
  },
  {
    field: "totalPn",
    headerName: "Total PN$",
    flex: 1,
    editable: true,
    type: "number", // Set the type explicitly
    minWidth: 120, // Minimum size
  },
  {
    field: "kamCostPerPart",
    headerName: "KAM Cost Per Part",
    flex: 1,
    editable: true,
    type: "number", // Set the type explicitly
    minWidth: 150, // Minimum size
  },
  {
    field: "quoteNum",
    headerName: "Quote #",
    flex: 1,
    editable: true,
    type: "string", // Set the type explicitly
    minWidth: 50, // Minimum size
  },
  {
    field: "payStatus",
    headerName: "Pay Status",
    flex: 1,
    editable: true,
    type: "boolean", // Set the type explicitly
    minWidth: 50, // Minimum size
  },
  {
    field: "poAmounts",
    headerName: "PO Amounts",
    flex: 1,
    editable: true,
    type: "number", // Set the type explicitly
    minWidth: 120, // Minimum size
  },
  {
    field: "kamPoNum",
    headerName: "KAM PO#",
    flex: 1,
    editable: true,
    type: "number", // Set the type explicitly
    minWidth: 100, // Minimum size
  },
  {
    field: "kamWoNum",
    headerName: "KAM WO#",
    flex: 1,
    editable: true,
    type: "string", // Set the type explicitly due to dashes
    minWidth: 150, // Minimum size
  },
  {
    field: "subsystem",
    headerName: "Subsystem",
    flex: 1,
    editable: true,
    type: "string", // Set the type explicitly
    minWidth: 120, // Minimum size
  },
  {
    field: "roughMaterial",
    headerName: "Rough Material",
    flex: 1,
    editable: true,
    type: "string", // Set the type explicitly
    minWidth: 120, // Minimum size
  },
  {
    field: "inserts",
    headerName: "Inserts",
    flex: 1,
    editable: true,
    type: "boolean", // Set the type explicitly
    minWidth: 50, // Minimum size
  },
  {
    field: "insertsPerPartNum",
    headerName: "Inserts/P#",
    flex: 1,
    editable: true,
    type: "number", // Set the type explicitly
    minWidth: 100, // Minimum size
  },
  {
    field: "insert1",
    headerName: "Insert 1",
    flex: 1,
    editable: true,
    type: "number", // Set the type explicitly
    minWidth: 100, // Minimum size
  },
];
