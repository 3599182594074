import * as React from "react";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/x-charts/themeAugmentation";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-tree-view/themeAugmentation";
import { alpha } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppNavbar from "../components/AppNavbar";
import { useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Snackbar,
} from "@mui/material";
import { useState } from "react";
import Auth from "../util/Auth";
import Header from "../components/Header";

import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from "../theme/customizations";
import AppTheme from "../shared-theme/AppTheme";
import ClientGrid from "../components/Clients/ClientGrid";
import ERP from "../util/ERP";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

const LogInComponent: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const [apiOnline, setAPIOnline] = useState<boolean>(true);

  useEffect(() => {
    const f = async () => {
      const healthy = await ERP.System.Health();
      setAPIOnline(healthy);
    };
    setInterval(f, 1000);
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    try {
      await Auth.LogIn(email, password);
      // Optionally redirect or show a success message
      setError("Login success");
      setOpenSnackbar(true);
    } catch (err) {
      setError("Failed to log in. Please check your credentials.");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="xs">
      {apiOnline ? (
        <>
          <Typography variant="h5" align="center" gutterBottom>
            Log In
          </Typography>
          <br />
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Log In
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      ) : (
        <>
          <Typography variant="h5" color="#FF0000" align="center" gutterBottom>
            YOU CANNOT LOGIN AT THIS TIME.
          </Typography>
          <Typography variant="h5" color="#FF0000" align="center" gutterBottom>
            THE API IS OFFLINE.
          </Typography>
        </>
      )}
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={error}
        autoHideDuration={6000}
      />
    </Container>
  );
};

export default function Login(props: { disableCustomTheme?: boolean }) {
  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: "flex" }}>
        <AppNavbar />
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.palette.background.default
              ? `rgba(${theme.palette.background.default} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: "auto",
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: "center",
              mx: 3,
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <LogInComponent />
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
