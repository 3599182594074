import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";

export type MetaDataTable = {
  creator: string;
  create_time: string;
  modifier?: string;
  modified_time?: string;
};

function MetaData(props: { data: MetaDataTable }) {
  const CNAME = props.data.creator;
  const CTS = props.data.create_time;
  const MNAME = props.data.modifier;
  const MTS = props.data.modified_time;

  return (
    <Box>
      {MNAME !== undefined && MTS !== undefined && (
        <Typography variant="body1" color="textSecondary" sx={{ mt: 0.5 }}>
          <i>
            Modified by {MNAME} on {new Date(MTS).toLocaleString()}
          </i>
        </Typography>
      )}
      <Typography variant="body2" color="textSecondary">
        <i>
          Created by {CNAME} on {new Date(CTS).toLocaleString()}
        </i>
      </Typography>
    </Box>
  );
}

export default MetaData;
