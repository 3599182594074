import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Box,
  Grid,
  Chip,
  Typography,
} from "@mui/material";
import {
  DndContext,
  DragStartEvent,
  DragOverEvent,
  DragEndEvent,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { usePreferences } from "../../managers/PreferenceContext";

interface SortOption {
  field: string;
  headerName: string;
}

interface SortCriteria {
  field: string;
  order: string;
}

interface AdvancedSortPopupProps {
  open: boolean;
  onClose: () => void;
  columns: SortOption[];
  onSortChange: (sortParams: SortCriteria[]) => void;
}

export default function AdvancedSortPopup({
  open,
  onClose,
  columns,
  onSortChange,
}: AdvancedSortPopupProps) {
  const [selectedField, setSelectedField] = useState(columns[0]?.field || "");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortCriteria, setSortCriteria] = useState<SortCriteria[]>([]);
  const [initialSortCriteria, setInitialSortCriteria] = useState<
    SortCriteria[]
  >([]);
  const { selectedProfile } = usePreferences();
  const [activeId, setActiveId] = useState<string | null>(null);

  useEffect(() => {
    const key = "userSortCriteria_" + selectedProfile;
    const saved = localStorage.getItem(key);
    if (saved) {
      const parsed = JSON.parse(saved);
      setSortCriteria(parsed);
      setInitialSortCriteria(parsed);
    } else {
      setSortCriteria([]);
      setInitialSortCriteria([]);
    }
  }, [selectedProfile]);

  useEffect(() => {
    if (sortCriteria.length === 0 && initialSortCriteria.length === 0) return;
    const key = "userSortCriteria_" + selectedProfile;
    localStorage.setItem(key, JSON.stringify(sortCriteria));
  }, [sortCriteria, selectedProfile, initialSortCriteria]);

  const handleAddSort = () => {
    if (!selectedField.trim() || !sortOrder) return;
    setSortCriteria((prev) => [
      ...prev,
      { field: selectedField, order: sortOrder },
    ]);
  };

  const handleRemoveSort = (field: string) => {
    setSortCriteria((prev) => prev.filter((x) => x.field !== field));
  };

  const handleFieldChange = (event: SelectChangeEvent<string>) => {
    setSelectedField(event.target.value);
  };

  const handleOrderChange = (event: SelectChangeEvent<string>) => {
    setSortOrder(event.target.value);
  };

  const handleSort = () => {
    onSortChange(sortCriteria);
    setInitialSortCriteria(sortCriteria);
    onClose();
  };

  const isSortChanged =
    JSON.stringify(sortCriteria) !== JSON.stringify(initialSortCriteria);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragStart = (e: DragStartEvent) => {
    setActiveId(e.active.id as string);
  };

  const handleDragOver = (e: DragOverEvent) => {
    if (!e.over) return;
    if (e.active.id !== e.over.id) {
      setSortCriteria((prev) => {
        const oldIndex = prev.findIndex((x) => x.field === e.active.id);
        const newIndex = prev.findIndex((x) => x.field === e.over?.id);
        return arrayMove(prev, oldIndex, newIndex);
      });
    }
  };

  const handleDragEnd = () => {
    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Advanced Sort</DialogTitle>
      <DialogContent>
        <Box mt={0}>
          {sortCriteria.length > 0 && (
            <>
              <div>Current Sort:</div>
              <DndContext
                sensors={sensors}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
              >
                <SortableContext
                  items={sortCriteria.map((x) => x.field)}
                  strategy={verticalListSortingStrategy}
                >
                  {sortCriteria.map(({ field, order }) => (
                    <SortableChip
                      key={field}
                      id={field}
                      text={`${field} (${order})`}
                      onRemove={() => handleRemoveSort(field)}
                    />
                  ))}
                </SortableContext>
                <DragOverlay>
                  {activeId && (
                    <Chip
                      style={{
                        margin: "0.5rem",
                        whiteSpace: "nowrap",
                        opacity: 0.8,
                      }}
                      color="primary"
                      label={(() => {
                        const item = sortCriteria.find(
                          (x) => x.field === activeId
                        );
                        return item
                          ? `${item.field} (${item.order})`
                          : activeId;
                      })()}
                    />
                  )}
                </DragOverlay>
              </DndContext>
            </>
          )}
        </Box>
        <div>Add Sort:</div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Sort by</InputLabel>
              <Select value={selectedField} onChange={handleFieldChange}>
                {columns.map((col) => (
                  <MenuItem key={col.field} value={col.field}>
                    {col.headerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Sort Order</InputLabel>
              <Select value={sortOrder} onChange={handleOrderChange}>
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddSort}>Add Sort</Button>
        <Button onClick={handleSort} disabled={!isSortChanged}>
          Sort
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface SortableChipProps {
  id: string;
  text: string;
  onRemove: () => void;
}

function SortableChip({ id, text, onRemove }: SortableChipProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
  });

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    margin: "0.5rem 0.5rem 0 0",
    whiteSpace: "nowrap",
    opacity: isDragging ? 0.75 : 1,
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onRemove();
  };

  return (
    <Chip
      ref={setNodeRef}
      style={style}
      color="primary"
      label={
        <Typography
          {...attributes}
          {...listeners}
          style={{
            cursor: "grab",
            userSelect: "none",
            whiteSpace: "nowrap",
            display: "inline-flex",
            alignItems: "center",
          }}
          variant="body2"
        >
          {text}
        </Typography>
      }
      onDelete={handleDelete}
    />
  );
}
