import React, { createContext, useState, useEffect, useContext } from "react";

interface PreferencesContextValue {
  selectedProfile: string;
  setSelectedProfile: (profile: string) => void;
}

export const PreferencesContext = createContext<PreferencesContextValue>({
  selectedProfile: "default",
  setSelectedProfile: () => {},
});

export const PreferencesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [selectedProfile, _setSelectedProfile] = useState("");

  useEffect(() => {
    const storedProfile = localStorage.getItem("selectedProfile");
    if (storedProfile) {
      _setSelectedProfile(storedProfile);
    }
  }, []);

  const setSelectedProfile = (profile: string) => {
    _setSelectedProfile(profile);
    localStorage.setItem("selectedProfile", profile);
  };

  return (
    <PreferencesContext.Provider
      value={{ selectedProfile, setSelectedProfile }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};

export const usePreferences = () => useContext(PreferencesContext);
