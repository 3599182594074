import ERP from "../../util/ERP";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { PO } from "../../util/Typings";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import DynamicProp from "../../util/DynamicProp";

type Row = { id: number; name: string; due: string; client: string };

const OutstandingPO = () => {
  const [data, setData] = useState<Row[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const docs = await ERP.GetCollection<PO>("cpo", [
          "due_date",
          "client",
          "status",
        ]);
        const outstandingDocs = docs.filter(
          (doc) =>
            dayjs() > dayjs(doc.due_date) &&
            doc.status.toLowerCase() !== "complete"
        );
        const parsedData = outstandingDocs.map((doc, index) => ({
          id: index + 1,
          name: doc.id,
          due: dayjs(doc.due_date).format("MMM D, YYYY"),
          client: doc.client,
        }));
        setData(parsedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetch();
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      {data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>PO#</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Customer</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Due Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <DynamicProp name="cpo" value={row.name} condensed />
                  </TableCell>

                  <TableCell>
                    <DynamicProp name="client" value={row.client} condensed />
                  </TableCell>
                  <TableCell>
                    <DynamicProp name="due_date" value={row.due} condensed />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">No Documents</Typography>
      )}
    </Box>
  );
};

export default OutstandingPO;
