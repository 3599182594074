import { ObjectType } from "../../util/Typings";

import { Box, Card, IconButton, NativeSelect, Typography } from "@mui/material";
import EditTools from "./EditTools";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import DynamicProp from "../../util/DynamicProp";
import ERP from "../../util/ERP";
import { notificationManager } from "../../managers/NotificationManager";

import Tooltip from "@mui/material/Tooltip";

const CreateButton = (props: { onCreate: () => void }) => {
  return (
    <Tooltip title="Auto create this object">
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          props.onCreate();
        }}
        sx={{
          ml: 2,
        }}
        endIcon={<AutoFixNormalIcon />}
      >
        Create
      </Button>
    </Tooltip>
  );
};

type FieldName =
  | string
  | "opNum"
  | "desc"
  | "resource"
  | "certified"
  | "firstArticle"
  | "totalComplete"
  | "qtyQueuedNextOp"
  | "breakDownComplete";
type Data = { [key: string]: boolean | string | number | any }[];

export type PropTableProps = {
  objectId: string;
  objectType: ObjectType;
  propData: { [key: string]: boolean | string | number | any }[];
  readOnly?: boolean;
};
export default function LineItems(props: PropTableProps) {
  const [isEditing, setEditMode] = useState(false);
  const [tableData, setTableData] = useState<Data>(props.propData || {});
  const [editIdx, setEditIdx] = useState(-1); // Track the row being edited
  const [editCount, setEditCount] = useState(0);

  useEffect(() => {
    setTableData(props.propData);
  }, [props.propData]);
  // Handle input change for editable fields
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: FieldName,
    index: number
  ) => {
    const newData = [...tableData];
    newData[index] = { ...newData[index], [field]: e.target.value }; // Update the relevant field
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleSelectChange = (
    index: number,
    fieldName: string,
    value: string
  ) => {
    const newData = [...tableData];
    newData[index] = { ...newData[index], [fieldName]: value };
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  // row arrangement
  const handleMoveUp = (index: number) => {
    const newData = [...tableData];
    [newData[index - 1], newData[index]] = [newData[index], newData[index - 1]];
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleMoveDown = (index: number) => {
    const newData = [...tableData];
    [newData[index + 1], newData[index]] = [newData[index], newData[index + 1]];
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  const handleDeleteRow = (index: number) => {
    const newData = tableData.filter((_, i) => i !== index);
    setTableData(newData);
    setEditCount(editCount + 1);
  };

  // Add new blank operation
  const handleAddLineItem = () => {
    const newOperation = {
      num: tableData.length + 1, // Set OP# to next available number
      item: "",
      quantity: "",
      ["due date"]: "",
    };

    setTableData([...tableData, newOperation]);
    setEditIdx(tableData.length); // Set the newly added row to be editable
    setEditCount(editCount + 1);
  };

  function handleToggleEditMode(editing: boolean) {
    setEditMode(editing);
  }
  function handleSave() {
    if (editCount > 0) {
      const parsedData = tableData;
      ERP.UpdateObject(props.objectType, props.objectId, [
        { name: "items", value: parsedData },
      ])
        .then((updatedData) => {
          console.log(updatedData);
          notificationManager.notify("success", "Changes saved.");
        })
        .catch((err) => {
          notificationManager.notify(
            "error",
            "Changes could not be saved: " + err
          );
        });
    } else {
      notificationManager.notify("warning", "No changes to save.");
    }
    setEditMode(false);
  }

  return (
    <Card sx={{ width: "100%", marginTop: 2 }}>
      <Box display="flex" flexDirection="row" justifyContent={"space-between"}>
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            verticalAlign: "bottom",
          }}
        >
          Line Items
        </Typography>
        <EditTools
          isEditing={isEditing}
          toggleEdit={handleToggleEditMode}
          onSave={handleSave}
        />
      </Box>
      <br />
      <TableContainer component={Paper} sx={{}}>
        <Table size="small" sx={{}}>
          <TableHead>
            <TableRow
              sx={{
                borderBottom: "2px solid rgba(0, 0, 0, 0.87)", // Adjust the thickness and color here
              }}
            >
              <TableCell>Item #</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>
                {props.objectType === "vpo" ? "Part/COTS" : "Part"}
              </TableCell>
              <TableCell>
                {props.objectType === "packingslip"
                  ? "Quantity Shipped"
                  : props.objectType === "rfq"
                  ? "ARO"
                  : props.objectType === "vpo"
                  ? "Status"
                  : "Workorder"}
              </TableCell>
              {isEditing && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  {isEditing ? (
                    <TextField
                      value={row.num}
                      onChange={(e) => handleChange(e, "num", index)}
                    />
                  ) : (
                    row.num
                  )}
                </TableCell>
                <TableCell>
                  {isEditing ? (
                    <TextField
                      value={row.quantity}
                      onChange={(e) => handleChange(e, "quantity", index)}
                    />
                  ) : (
                    row.quantity
                  )}
                </TableCell>

                {/* Editable Resource Dropdown */}
                <TableCell>
                  <DynamicProp
                    value={row.part}
                    name={props.objectType === "vpo" ? "part&cots" : "part"}
                    editMode={isEditing}
                    condensed
                    onEdit={(newValue) => {
                      handleSelectChange(index, "part", newValue as string);
                    }}
                  />
                </TableCell>
                {props.objectType === "rfq" && (
                  <TableCell>
                    <DynamicProp
                      value={row.aro}
                      name="aro"
                      editMode={isEditing}
                      condensed
                      onEdit={(newValue) => {
                        handleSelectChange(index, "aro", newValue as string);
                      }}
                    />
                  </TableCell>
                )}
                {props.objectType === "packingslip" && (
                  <TableCell>
                    <DynamicProp
                      value={row.quantity_shipped}
                      name="quantity_shipped"
                      editMode={isEditing}
                      condensed
                      onEdit={(newValue) => {
                        handleSelectChange(
                          index,
                          "quantity_shipped",
                          newValue as string
                        );
                      }}
                    />
                  </TableCell>
                )}
                {props.objectType === "cpo" && (
                  <TableCell>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <DynamicProp
                        value={row.workorder}
                        name="workorder"
                        editMode={isEditing}
                        condensed
                        onEdit={(newValue) => {
                          handleSelectChange(
                            index,
                            "workorder",
                            newValue as string
                          );
                        }}
                      />
                      {row.workorder === "" && isEditing && (
                        <CreateButton
                          onCreate={() => {
                            notificationManager.notify(
                              "info",
                              "Creating work order..."
                            );

                            console.log(
                              "CREATE WO: ",
                              row.part,
                              props.objectId
                            );
                            ERP.CreateObject("workorder", {
                              name: "$AUTO",
                              part: row.part,
                              cpo: props.objectId,
                              quantity: row.quantity,
                              //add due date here
                            })
                              .then((workorder) => {
                                notificationManager.notify(
                                  "success",
                                  `Workorder created successfully.`
                                );
                                handleSelectChange(
                                  index,
                                  "workorder",
                                  workorder.id
                                );
                              })
                              .catch((err) => {
                                //
                              });
                          }}
                        />
                      )}
                    </Box>
                  </TableCell>
                )}

                {props.objectType === "vpo" && (
                  <TableCell>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <DynamicProp
                        value={row.status}
                        name="vpostatus"
                        editMode={isEditing}
                        condensed
                        onEdit={(newValue) => {
                          handleSelectChange(
                            index,
                            "status",
                            newValue as string
                          );
                        }}
                      />
                    </Box>
                  </TableCell>
                )}

                {isEditing && (
                  <TableCell>
                    <IconButton
                      onClick={() => handleMoveUp(index)}
                      disabled={index === 0}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleMoveDown(index)}
                      disabled={index === tableData.length - 1}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteRow(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isEditing && (
        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          onClick={handleAddLineItem}
        >
          Add New Item
        </Button>
      )}
    </Card>
  );
}
