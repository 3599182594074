import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { Collapse } from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  ExpandLess,
  ExpandMore,
  FileUploadOutlined,
} from "@mui/icons-material";
import FactoryIcon from "@mui/icons-material/Factory";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import WorkIcon from "@mui/icons-material/Work";
import GroupsIcon from "@mui/icons-material/Groups";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import HandymanIcon from "@mui/icons-material/Handyman";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import PestControlIcon from "@mui/icons-material/PestControl";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Shop2Icon from "@mui/icons-material/Shop2";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ERP from "../util/ERP";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ExploreIcon from "@mui/icons-material/Explore";
const mainListItems = [
  { text: "Home", permLevel: 1, icon: <HomeRoundedIcon /> },
  {
    text: "Projects",
    permLevel: 1,
    icon: <ExploreIcon />,
  },
  {
    text: "Contacts",
    permLevel: 3,
    icon: <GroupsIcon />,
    items: [
      {
        text: "Clients",
        icon: <PeopleRoundedIcon />,
        path: "/objects/contacts?filter=client",
      },
      {
        text: "Vendors",
        icon: <BusinessIcon />,
        path: "/objects/contacts?filter=vendor",
      },
      {
        text: "People",
        icon: <PersonIcon />,
        path: "/objects/contacts?filter=person",
      },
    ],
  },
  {
    text: "Management",
    permLevel: 3,
    icon: <ApartmentIcon />,
    items: [
      { text: "RFQ", icon: <RequestPageIcon />, path: "/objects/rfqs" },
      { text: "Vendor PO's", icon: <Shop2Icon />, path: "/objects/vpos" },
      { text: "Customer PO's", icon: <Shop2Icon />, path: "/objects/cpos" },
    ],
  },
  {
    text: "Production",
    permLevel: 1,
    icon: <FactoryIcon />,
    items: [
      {
        text: "Resources",
        icon: <PrecisionManufacturingIcon />,
        path: "/objects/resources",
      },
      { text: "Work Orders", icon: <WorkIcon />, path: "/objects/workorders" },
      { text: "Parts", icon: <HandymanIcon />, path: "/objects/parts" },
      { text: "Schedule", icon: <CalendarMonthIcon />, path: "/schedule" },
    ],
  },
  {
    text: "Administration",
    permLevel: 5,
    icon: <AdminPanelSettingsIcon />,
    items: [
      { text: "Users", icon: <PeopleRoundedIcon />, path: "/admin/users" },
      {
        text: "System Config",
        icon: <ArchitectureIcon />,
        path: "/admin/config",
      },
      { text: "Debug", icon: <PestControlIcon />, path: "/admin/debug" },
    ],
  },
  //{ text: 'Analytics', icon: <AnalyticsRoundedIcon /> },
  /**{ text: 'Sales', icon: <AttachMoneyIcon />, items: [
    {
      text: "RFQ", icon: <RequestQuoteIcon />
    },
    {
      text: "Quotes", icon: <FormatQuoteIcon />
    },
    {
      text: "Customer PO's", icon: <ShopIcon />
    },
  ]},*/
  {
    text: "Inventory",
    permLevel: 3,
    icon: <InventoryIcon />,
    items: [{ text: "COTS", icon: <WarehouseIcon />, path: "/objects/cots" }],
  },
  {
    text: "Shipping",
    permLevel: 3,
    icon: <LocalShippingIcon />,
    items: [
      {
        text: "Packing Slips",
        icon: <ReceiptIcon />,
        path: "/objects/packingslips",
      },
    ],
  },
  //{ text: 'Tasks', icon: <AssignmentRoundedIcon /> },
];

/**
 * NOT PRODUCTION READY
 * {
    text: "Import Records",
    permLevel: 3,
    icon: <UploadFileIcon />,
    path: "/import",
  },
 */

const secondaryListItems = [
  { text: "Create", permLevel: 3, icon: <AutoFixHighIcon />, path: "/create" },
  {
    text: "Time Clock",
    permLevel: 1,
    icon: <PunchClockIcon />,
    path: "/timeclock",
  },
];

export default function MenuContent() {
  const goto = useNavigate();
  const navigate = (path: string) => {
    goto(path, { state: { key: Date.now() } });
  };
  const location = useLocation();
  const [openCategory, setOpenCategory] = useState<number | null>(null);
  const [USER_PERM_LEVEL, _SET_USER_PERM_LEVEL] = useState(0);

  const handleClick = (index: number) => {
    if (openCategory === index) {
      setOpenCategory(null);
    } else {
      setOpenCategory(index);
    }
  };

  useEffect(() => {
    const getData = () => {
      ERP.Users.GetUserData().then((data) => {
        _SET_USER_PERM_LEVEL(Number(data.permission_level || "0"));
      });
    };

    getData();
  }, []);

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {mainListItems
          .filter((v) => {
            return v.permLevel ? USER_PERM_LEVEL >= v.permLevel : false;
          })
          .map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              {item.items === undefined ? (
                <ListItemButton
                  selected={location.pathname.split("/")[1] === item.text}
                  onClick={() => {
                    navigate(`/${item.text}`);
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ) : (
                <>
                  <ListItemButton onClick={() => handleClick(index)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                    {openCategory === index ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={openCategory === index}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.items.map((subItem, subIndex) => (
                        <ListItemButton
                          key={subIndex}
                          sx={{ pl: 4 }}
                          selected={
                            location.pathname.split("/")[2] === subItem.text
                          }
                          onClick={() => navigate(subItem.path)}
                        >
                          <ListItemIcon>{subItem.icon}</ListItemIcon>
                          <ListItemText primary={subItem.text} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </>
              )}
            </ListItem>
          ))}
      </List>

      <List dense>
        {secondaryListItems
          .filter((v) => {
            return v.permLevel ? USER_PERM_LEVEL >= v.permLevel : false;
          })
          .map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => {
                  navigate(item.path);
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Stack>
  );
}
