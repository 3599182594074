import { Box, Card, Typography } from "@mui/material";
import EditTools from "./EditTools";
import { useEffect, useState } from "react";
import ERP from "../../util/ERP";
import { ObjectType, Part } from "../../util/Typings";

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { objectType: ObjectType; objectId: string }) => {
  const [data, setData] = useState<{ [key: string]: any }>({});
  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    const getData = () => {
      ERP.GetObject<Part>(props.objectType, props.objectId)
        .then((obj) => {
          setData({ quantity_inventory: obj.quantity_inventory });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, [props.objectId, props.objectType]);

  return (
    <Card sx={{ mt: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">
          Inventory <i>({data.quantity_inventory})</i>
        </Typography>
        <EditTools
          isEditing={isEditing}
          onSave={() => {}}
          toggleEdit={(e) => {
            setEditing(e);
          }}
        />
      </Box>
    </Card>
  );
};
