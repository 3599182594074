import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";

interface ColumnOption {
  field: string;
  headerName?: string;
}

interface ToggleColumnsProps {
  open: boolean;
  onClose: () => void;
  columns: ColumnOption[];
  visibleColumns: string[];
  onColumnVisibilityChange: (visibleColumns: string[]) => void;
}

const ToggleColumns: React.FC<ToggleColumnsProps> = ({
  open,
  onClose,
  columns,
  visibleColumns,
  onColumnVisibilityChange,
}) => {
  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(visibleColumns);

  useEffect(() => {
    setSelectedColumns(visibleColumns);
  }, [visibleColumns]);
  const handleToggleColumn = (field: string) => {
    setSelectedColumns((prev) =>
      prev.includes(field)
        ? prev.filter((col) => col !== field)
        : [...prev, field]
    );
  };

  const handleApply = () => {
    onColumnVisibilityChange(selectedColumns);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Toggle Columns</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {columns.map((col) => (
            <Grid item xs={6} key={col.field}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedColumns.includes(col.field)}
                      onChange={() => handleToggleColumn(col.field)}
                    />
                  }
                  label={col.headerName}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleApply} color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ToggleColumns;
