import { PieChart } from "@mui/x-charts";
import ERP from "../../util/ERP";
import { useEffect, useState } from "react";

type Row = { id: number; value: number; label: string };

const WOBreakdown = () => {
  const [data, setData] = useState<Row[]>([]);

  useEffect(() => {
    const fetch = () => {
      ERP.SummarizeDocs("workorders", "status")
        .then((docs) => {
          const rows: Row[] = [];
          for (let prop in docs) {
            const count = docs[prop];
            rows.push({ id: rows.length, value: count, label: prop });
          }
          setData(rows);
        })
        .catch((err) => {
          //
        });
    };

    fetch();
  }, []);

  return (
    <PieChart
      series={[
        {
          data,
        },
      ]}
      height={200}
      width={400}
    />
  );
};

export default WOBreakdown;
