import React, { useState } from "react";
import {
  DndContext,
  closestCenter,
  DragEndEvent,
  DragOverlay,
  UniqueIdentifier,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { Grid, Box, Typography, IconButton, Card } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { PieChart } from "@mui/x-charts/PieChart";
import WOBreakdown from "../Widgets/WOBreakdown";
import POBreakdown from "../Widgets/POBreakdown";
import OutstandingPO from "../Widgets/OutstandingPO";
import OutstandingVPO from "../Widgets/OutstandingVPO";
import QuotePerformance from "../Widgets/QuotePerformance";

// Example widget data
const exampleData = [
  {
    id: "1",
    title: "Workorder Breakdown",
    content: <WOBreakdown />,
  },
  {
    id: "2",
    title: "PO Breakdown",
    content: <POBreakdown />,
  },
  {
    id: "3",
    title: "Outstanding Cust. PO's",
    content: <OutstandingPO />,
  },
  {
    id: "4",
    title: "Overdue Vendor PO's",
    content: <OutstandingVPO />,
  },
  {
    id: "5",
    title: "Quote Win/Loss",
    content: <QuotePerformance />,
  },
];

const StatCard = (props: {
  name: string;
  content: JSX.Element;
  dragHandleProps: any;
}) => (
  <Card
    sx={{
      display: "flex",
      flexDirection: "column",
      height: 300, // Set a fixed height
      overflow: "hidden", // Ensure no overflow beyond the card boundaries
    }}
  >
    <Box
      sx={{
        justifyContent: "space-between",
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: 1 }}>
        {props.name}
      </Typography>
      <IconButton {...props.dragHandleProps}>
        <DragIndicatorIcon />
      </IconButton>
    </Box>
    <Box
      sx={{
        flex: 1, // Allow this box to take up the remaining space
        overflowY: "auto", // Enable vertical scrolling
        padding: 1, // Add padding for better content display
      }}
    >
      {props.content}
    </Box>
  </Card>
);

// SortableCard Component
const SortableCard = (props: {
  id: string;
  name: string;
  content: JSX.Element;
}) => {
  const { id } = props;
  const {
    setNodeRef,
    transform,
    transition,
    isDragging,
    listeners,
    attributes,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : "auto",
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <StatCard
        name={props.name}
        content={props.content}
        dragHandleProps={{ ...listeners, ...attributes }}
      />
    </Box>
  );
};

// WidgetGrid Component
const WidgetGrid: React.FC = () => {
  const [data, setData] = useState(exampleData);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  // Handle drag end
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = data.findIndex((item) => item.id === active.id);
      const newIndex = data.findIndex((item) => item.id === over.id);

      setData((prev) => arrayMove(prev, oldIndex, newIndex));
    }

    setActiveId(null); // Reset active card
  };

  // Get the active card for DragOverlay
  const activeCard = activeId
    ? data.find((card) => card.id === activeId)
    : null;

  return (
    <Box sx={{ width: "100%", margin: "auto" }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Widgets
      </Typography>
      <DndContext
        collisionDetection={closestCenter}
        onDragStart={({ active }) => setActiveId(active.id)}
        onDragEnd={handleDragEnd}
        onDragCancel={() => setActiveId(null)}
      >
        <SortableContext
          items={data.map((item) => item.id)}
          strategy={rectSortingStrategy}
        >
          <Grid container spacing={2}>
            {data.map((card) => (
              <Grid item xs={12} sm={6} key={card.id}>
                <SortableCard
                  id={card.id}
                  name={card.title}
                  content={card.content}
                />
              </Grid>
            ))}
          </Grid>
        </SortableContext>
        <DragOverlay>
          {activeCard ? (
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignContent: "center",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: 1 }}>
                {activeCard.title}
              </Typography>
              <Box sx={{ display: "flex" }}>{activeCard.content}</Box>
            </Card>
          ) : null}
        </DragOverlay>
      </DndContext>
    </Box>
  );
};

export default WidgetGrid;
