import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridSortItem } from "@mui/x-data-grid";
import {
  collection,
  doc,
  onSnapshot,
  updateDoc,
  getDoc,
  setDoc,
  serverTimestamp,
  deleteField,
  deleteDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../util/Firebase";
import ERP from "../../util/ERP";
import { User } from "../../util/Typings";
import { columnsData } from "./ProjectPlannerColumns";
import { Box, Button } from "@mui/material";
import AdvancedSortPopup from "./AdvancedSortPopUp";
import ToggleColumns from "./ToggleColumns";
import CustomToolbar from "./ProjectPlannerGridTools";
import { usePreferences } from "../../managers/PreferenceContext";

interface ProjectPlannerRow {
  id: string;
  status: string;
  pn: string;
  description: string;
  qty: number;
  material: string;
  materialProvided: boolean;
}

interface UpdateTrackerValue {
  rowId: string;
  field: string;
  timestamp: any;
}

const UPDATE_TIMEOUT_MS = 60000;

export default function ProjectPlanner() {
  const [openSortPopup, setOpenSortPopup] = useState(false);
  const [rows, setRows] = useState<ProjectPlannerRow[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const { selectedProfile } = usePreferences();

  const [isToggleColumnsDialogOpen, setToggleColumnsDialogOpen] =
    useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [activeUpdates, setActiveUpdates] = useState<
    Record<string, UpdateTrackerValue>
  >({});
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    { field: "status", sort: "asc" },
  ]);

  const projectPlannerCollection = collection(db, "projectplanner");
  const updateTrackerDoc = doc(db, "projectplanner", "updateTracker");
  const auth = getAuth();
  const currentUser = auth.currentUser;

  // Example: cast or conditionally set the "type" for each column
  const columns: GridColDef[] = columnsData.map(
    ({ field, headerName, flex, minWidth, editable, type, options }) => {
      const gridType = type;

      return {
        field,
        headerName,
        flex,
        minWidth,
        editable,
        type: gridType,
        valueOptions: options,
        cellClassName: (params) => {
          let foundKey = "";
          const update = Object.entries(activeUpdates).find(([key, value]) => {
            const match = value.rowId === params.id && value.field === field;
            if (match) foundKey = key;
            return match;
          });
          let className = "cell-right-border";
          if (update) {
            const userEmailParsed = foundKey.replace("_", ".");
            const color = generateColorFromUserId(userEmailParsed);
            const borderColor = darkenColor(color);
            className += ` cell-highlight-${color} cell-border-${borderColor}`;
          }
          return className;
        },
      } as GridColDef;
    }
  );

  useEffect(() => {
    const key = "visibleColumns_" + selectedProfile;
    const stored = localStorage.getItem(key);
    if (stored) {
      console.log("LOADED: ", JSON.parse(stored));
      setVisibleColumns(JSON.parse(stored));
    } else {
      // All columns enabled by default
      setVisibleColumns(columnsData.map((c) => c.field));
    }
  }, [selectedProfile]);

  useEffect(() => {
    const initTracker = async () => {
      const snap = await getDoc(updateTrackerDoc);
      if (!snap.exists()) {
        await setDoc(updateTrackerDoc, {});
      }
    };
    initTracker();

    const unsubscribeRows = onSnapshot(projectPlannerCollection, (snap) => {
      const fetchedRows = snap.docs
        .filter((d) => d.id !== "updateTracker")
        .map((d) => ({
          id: d.id,
          ...d.data(),
        })) as ProjectPlannerRow[];

      const sorted = fetchedRows.sort((a, b) => {
        const empty = (r: ProjectPlannerRow) =>
          !r.status && !r.pn && !r.description && !r.material;
        if (empty(a) && !empty(b)) return 1;
        if (!empty(a) && empty(b)) return -1;
        return 0;
      });
      setRows(sorted);
    });

    const unsubscribeUpdates = onSnapshot(updateTrackerDoc, (docSnap) => {
      if (docSnap.exists()) {
        const now = Date.now();
        const data = docSnap.data() || {};
        const filtered = Object.fromEntries(
          Object.entries(data).filter(
            ([_, upd]: [string, UpdateTrackerValue]) => {
              return (
                upd.timestamp?.toMillis() &&
                now - upd.timestamp.toMillis() <= UPDATE_TIMEOUT_MS
              );
            }
          )
        );
        setActiveUpdates(filtered);
      }
    });

    return () => {
      unsubscribeRows();
      unsubscribeUpdates();
    };
  }, [currentUser]);

  const handleCellEditStart = async (params: any) => {
    if (!currentUser?.email) return;
    const userEmailParsed = currentUser.email.replace(".", "_");
    await updateDoc(updateTrackerDoc, {
      [userEmailParsed]: {
        rowId: params.id,
        field: params.field,
        timestamp: serverTimestamp(),
      },
    });
  };

  const handleCellEditStop = async (params: any) => {
    if (!currentUser?.email) return;
    const userEmailParsed = currentUser.email.replace(".", "_");
    await updateDoc(updateTrackerDoc, {
      [userEmailParsed]: deleteField(),
    });
  };

  const processRowUpdate = async (
    newRow: ProjectPlannerRow,
    oldRow: ProjectPlannerRow
  ) => {
    const { id, ...rest } = newRow;
    try {
      await updateDoc(doc(db, "projectplanner", id), rest);
    } catch {
      return oldRow;
    }
    return newRow;
  };

  const handleSortChange = (sortParams: { field: string; order: string }[]) => {
    const newRows = [...rows];
    newRows.sort((a, b) => {
      for (const { field, order } of sortParams) {
        const valA = a[field as keyof ProjectPlannerRow];
        const valB = b[field as keyof ProjectPlannerRow];
        if (valA === valB) continue; // move to next sort param if equal

        if (order === "asc") {
          if (valA > valB) return 1;
          return -1;
        } else {
          if (valA < valB) return 1;
          return -1;
        }
      }
      return 0;
    });
    setRows(newRows);
    setSortModel(
      sortParams.map(({ field, order }) => ({
        field,
        sort: order as "asc" | "desc",
      }))
    );
  };

  const generateColorFromUserId = (uid: string) => {
    const parsed = uid.replace("_", ".");
    const hash = parsed.split("").reduce((acc, c) => acc + c.charCodeAt(0), 0);
    const colors = [
      "lightblue",
      "lavender",
      "peach",
      "coral",
      "slate",
      "turquoise",
      "lime",
    ];
    return colors[hash % colors.length];
  };

  const darkenColor = (color: string) => {
    const map: { [key: string]: string } = {
      lightblue: "deepskyblue",
      lavender: "blueviolet",
      peach: "tomato",
      mint: "mediumseagreen",
      coral: "firebrick",
      slate: "darkslategray",
      turquoise: "darkturquoise",
      lime: "forestgreen",
    };
    return map[color] || color;
  };

  function UserCard({ uid }: { uid: string }) {
    const [email, setEmail] = useState("...");
    useEffect(() => {
      ERP.GetObject<User>("user", uid).then((data) => {
        setEmail(`${data.first_name} ${data.last_name}`);
      });
    }, [uid]);

    const userEmailParsed = uid.replace(".", "_");
    const bg = generateColorFromUserId(userEmailParsed);
    const borderColor = darkenColor(bg);
    return (
      <div
        style={{
          backgroundColor: bg,
          borderColor,
          borderWidth: "1px",
          borderStyle: "solid",
          color: "black",
          padding: "5px 10px",
          borderRadius: "20px",
          marginRight: "10px",
          display: "inline-block",
        }}
      >
        {email}
      </div>
    );
  }

  function RenderEditingUsers() {
    return (
      <div style={{ marginBottom: "10px", whiteSpace: "nowrap" }}>
        {Object.entries(activeUpdates).map(([uid]) => (
          <UserCard key={uid} uid={uid.replace("_", ".")} />
        ))}
      </div>
    );
  }

  function handleColumnVisibilityChange(updated: string[]) {
    const key = "visibleColumns_" + selectedProfile;
    localStorage.setItem(key, JSON.stringify(updated));
    setVisibleColumns(updated);
  }

  const filteredColumns = columns.filter((col) =>
    visibleColumns.includes(col.field)
  );

  async function handleDeleteRows() {
    try {
      await Promise.all(
        selectedRows.map((id) => deleteDoc(doc(db, "projectplanner", id)))
      );
      setRows((prev) => prev.filter((r) => !selectedRows.includes(r.id)));
      setSelectedRows([]);
    } catch {}
  }

  async function handleAddRow() {
    const newRow: Omit<ProjectPlannerRow, "id"> = {
      status: "",
      pn: "",
      description: "",
      qty: 0,
      material: "",
      materialProvided: false,
    };
    try {
      const ref = doc(collection(db, "projectplanner"));
      await setDoc(ref, newRow);
    } catch {}
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <style>
        {`
          .cell-right-border {
            border-right: 1px solid #ccc;
          }
          .cell-highlight-lightblue {
            background-color: lightblue !important;
          }
          .cell-highlight-lavender {
            background-color: lavender !important;
          }
          .cell-highlight-peach {
            background-color: peach !important;
          }
          .cell-highlight-coral {
            background-color: lightcoral !important;
          }
          .cell-highlight-slate {
            background-color: slategray !important;
          }
          .cell-highlight-turquoise {
            background-color: turquoise !important;
          }
          .cell-highlight-lime {
            background-color: lightgreen !important;
          }
        `}
      </style>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <RenderEditingUsers />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
          }}
        >
          <Button onClick={() => setOpenSortPopup(true)} variant="contained">
            Advanced Sort
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            onClick={() => setToggleColumnsDialogOpen(true)}
          >
            Toggle Columns
          </Button>
        </Box>
      </Box>
      <br />

      <DataGrid
        rows={rows}
        columns={filteredColumns}
        density="compact"
        rowHeight={30}
        onCellEditStart={handleCellEditStart}
        onCellEditStop={handleCellEditStop}
        processRowUpdate={processRowUpdate}
        onRowSelectionModelChange={(newSel) =>
          setSelectedRows(newSel.map(String))
        }
        disableRowSelectionOnClick
        checkboxSelection
        showCellVerticalBorder
        showColumnVerticalBorder
        sx={{
          "& .MuiDataGrid-cell": { borderRight: "1px solid #ccc" },
          "& .MuiDataGrid-columnHeader": {
            borderRight: "1px solid #ccc",
            borderTop: "1px solid #ccc",
          },
          ".MuiDataGrid-footerContainer": {
            minHeight: "30px",
            maxHeight: "35px",
            padding: "0",
          },
        }}
        slots={{
          toolbar: CustomToolbar(
            () => handleAddRow(),
            () => handleDeleteRows(),
            selectedRows.length > 0
          ),
        }}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model as GridSortItem[])}
      />

      <br />
      <ToggleColumns
        open={isToggleColumnsDialogOpen}
        onClose={() => setToggleColumnsDialogOpen(false)}
        columns={columns}
        visibleColumns={visibleColumns}
        onColumnVisibilityChange={handleColumnVisibilityChange}
      />
      <AdvancedSortPopup
        open={openSortPopup}
        onClose={() => setOpenSortPopup(false)}
        columns={columns.map((c) => ({
          field: c.field,
          headerName: c.headerName || c.field,
        }))}
        onSortChange={handleSortChange}
      />
    </div>
  );
}
