import * as React from "react";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Copyright from "../../internals/components/Copyright";
import StatCard, { StatCardProps } from ".././StatCard";
import ERP from "../../util/ERP";
import { notificationManager } from "../../managers/NotificationManager";
import { useState, useEffect } from "react";
import { TypeDisplayNames } from "../../util/Typings";
/**
 * 
 * const data: StatCardProps[] = [
  {
    title: "Todays Profit",
    value: "14k",
    interval: "Last 30 days",
    trend: "up",
    data: [
      200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340,
      380, 360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
    ],
  },
  {
    title: "Todays expenses",
    value: "325",
    interval: "Last 30 days",
    trend: "down",
    data: [1, 2, 0.5],
  },
  {
    title: "Event count",
    value: "200k",
    interval: "Last 30 days",
    trend: "neutral",
    data: [
      500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510,
      530, 520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
    ],
  },
];
 */

export default function MainGrid() {
  const [data, setData] = useState<StatCardProps[]>([]);
  useEffect(() => {
    ERP.GetAnalytics()
      .then((analytics) => {
        const pushData: StatCardProps[] = [];
        for (let metric in analytics) {
          const value = analytics[metric];
          pushData.push({
            title: `${
              TypeDisplayNames[
                metric.substring(0, metric.length - 1) as "contact"
              ] || metric
            }${"s"}`,
            value: `${value}`,
            interval: "Last 30 Days",
            trend: "neutral",
            data: [].concat(...Array(30).fill(value)),
          });
        }

        setData(pushData);
      })
      .catch((err) => {
        notificationManager.notify("error", err);
      });
  }, []);
  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      {/* cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Overview
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCard {...card} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
