import ERP from "../../util/ERP";
import { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts";

type Row = { id: number; value: number; label: string; color: string };

function GetColor(prop: string): string {
  switch (prop) {
    case "Won":
      return "green";
    case "Lost":
      return "red";
  }
  return "gray";
}

const QuotePerformance = () => {
  const [data, setData] = useState<Row[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const docs = await ERP.SummarizeDocs("rfqs", "quote_status");
        const total = Object.values(docs).reduce(
          (sum, count) => sum + count,
          0
        );
        const rows: Row[] = [];
        for (let prop in docs) {
          const count = docs[prop];
          rows.push({
            id: rows.length,
            value: count,
            label: `${prop === "Won" || prop === "Lost" ? prop : "Other"} (${(
              (count / total) *
              100
            ).toFixed(1)}%)`,
            color: GetColor(prop),
          });
        }
        setData(rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetch();
  }, []);

  return (
    <PieChart
      series={[
        {
          type: "pie",
          data,
        },
      ]}
      height={200}
    />
  );
};

export default QuotePerformance;
