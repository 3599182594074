import React, { useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Dashboard from "./pages/Dashboard";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import Error from "./pages/Error";
import Login from "./pages/Login";
import { AuthContext, AuthProvider } from "./managers/Contexts";
import Create from "./pages/Create";
import { CircularProgress } from "@mui/material";
import { UserProvider, useUser } from "./components/Core/UserContext";
import NotificationSystem from "./components/Core/NotificationSystem";
import TimeClock from "./components/Timeclock/TimeClock";
import Debug from "./pages/Debug";
import ObjPage from "./pages/ObjPage";
import Schedule from "./pages/Schedule";
import Printer from "./pages/Printer";
import { ObjectType } from "./util/Typings";
import SystemConfig from "./pages/SystemConfig";
import UploadDocs from "./pages/UploadDocs";
import RouteNotFound from "./pages/Error"; // Import RouteNotFound component
import ProjectPlanner from "./pages/ProjectPlannerPage";
import { PreferencesProvider } from "./managers/PreferenceContext";

// Extend the Three.js namespace
declare global {
  namespace JSX {
    interface IntrinsicElements {
      // Define a primitive for OBJ
      obj: any; // or you can specify a more specific type if needed
    }
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const AuthConsumer: React.FC = () => {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [authToken, setAuthToken] = useState<string | null>(null);

  useEffect(() => {
    if (authContext && authContext.currentUser) {
      authContext.currentUser.getIdToken().then((token: string) => {
        setAuthToken(token);
        setLoading(false);
      });
    } else {
      setLoading(false); // No user found, stop loading
    }
  }, [authContext]);

  if (loading || !authContext) {
    return <CircularProgress />;
  }

  const { currentUser } = authContext;

  if (currentUser && authToken === null) {
    return <CircularProgress />;
  }

  return <MainConsumer />;
};

const ObjectTypeHandler = () => {
  const { objectType } = useParams();
  if (!objectType) {
    return <Error />;
  }
  const objType = objectType.slice(0, objectType.length - 1) as ObjectType;
  return <ObjPage objType={objType} />;
};

function MainConsumer() {
  const { user } = useUser();
  const authContext = useContext(AuthContext);

  // Ensure user is authenticated
  if (!authContext?.currentUser) {
    return <Login />;
  }

  // Ensure proper rendering based on user authentication status
  return (
    <Routes>
      <Route
        path="/"
        element={
          user.id !== "" && authContext?.currentUser ? (
            <Navigate to="/home" />
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="login"
        element={
          user.id !== "" && authContext?.currentUser ? (
            <Navigate to="/home" />
          ) : (
            <Login />
          )
        }
      />
      <Route path="/objects/:objectType/*" element={<ObjectTypeHandler />} />
      <Route
        path="/schedule"
        element={
          user.id !== "" && authContext?.currentUser ? (
            <Schedule />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/create"
        element={
          user.id !== "" && authContext?.currentUser ? (
            <Create />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="/print" element={<Printer />} />
      <Route
        path="home"
        element={
          user.id !== "" && authContext?.currentUser ? (
            <Dashboard />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="projects"
        element={
          user.id !== "" && authContext?.currentUser ? (
            <ProjectPlanner />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="timeclock"
        element={
          user.id !== "" && authContext?.currentUser ? (
            <TimeClock />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="admin/debug"
        element={
          user.id !== "" && authContext?.currentUser ? (
            <Debug />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="admin/users"
        element={
          user.id !== "" && authContext?.currentUser ? (
            <ObjPage objType="user" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="admin/config" element={<SystemConfig />} />
      <Route
        path="admin/users/*"
        element={
          user.id !== "" && authContext?.currentUser ? (
            <ObjPage objType="user" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="import" element={<UploadDocs />} />
      {/* Add the fallback route for unmatched routes */}
      <Route path="*" element={<RouteNotFound />} />
    </Routes>
  );
}

// Root render with context providers
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <PreferencesProvider>
          <UserProvider>
            <NotificationSystem />
            <AuthConsumer />
          </UserProvider>
        </PreferencesProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);

// WebVitals logging (for performance metrics)
reportWebVitals();
