import { Box, Button, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

function CustomToolbar(
  handleAdd: () => void,
  handleDelete: () => void,
  isDeleteMode?: boolean
) {
  return () => (
    <GridToolbarContainer sx={{ mb: 0.5 }}>
      <GridToolbarFilterButton />
      <Box sx={{ flexGrow: 1 }} />
      <Tooltip title={isDeleteMode ? "Delete Row(s)" : "Add Row"}>
        <IconButton
          onClick={() => {
            if (isDeleteMode === true) {
              handleDelete();
            } else handleAdd();
          }}
          color="primary"
          sx={{ width: "35px", height: "35px" }}
        >
          {isDeleteMode ? <DeleteForeverIcon /> : <AddIcon />}
        </IconButton>
      </Tooltip>
      <GridToolbarExport
        slotProps={{
          tooltip: { title: "Export data" },
          button: { variant: "outlined" },
        }}
      />
    </GridToolbarContainer>
  );
}

export default CustomToolbar;
