import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import PreviewIcon from "@mui/icons-material/Preview";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ERP from "../../util/ERP";
import { User } from "../../util/Typings";
import { useNavigate } from "react-router-dom";
import { usePreferences } from "../../managers/PreferenceContext";
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

export default function ProfileSelect() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [profiles, setProfiles] = React.useState<string[]>([]);
  const { selectedProfile, setSelectedProfile } = usePreferences();
  const navigate = useNavigate();

  React.useEffect(() => {
    const loadProfiles = () => {
      ERP.GetObject<User>("user", ERP.LocalUser.ID()).then((user) => {
        setProfiles(user.preferences || []);
      });
    };

    loadProfiles();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Preferences
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple disabled>
          Current Profile:
        </MenuItem>
        {selectedProfile === "default" ? (
          <MenuItem onClick={handleClose} disableRipple>
            <PreviewIcon />
            Default
          </MenuItem>
        ) : (
          <MenuItem onClick={handleClose} disableRipple>
            <VisibilityIcon />
            {selectedProfile}
          </MenuItem>
        )}
        <Divider sx={{ my: 0.5 }} />
        {selectedProfile !== "default" && (
          <MenuItem
            onClick={() => {
              handleClose();
              setSelectedProfile("default");
            }}
            disableRipple
          >
            <PreviewIcon />
            Default
          </MenuItem>
        )}
        {profiles
          .filter((p) => p !== selectedProfile)
          .map((profileName) => (
            <MenuItem
              onClick={() => {
                handleClose();
                setSelectedProfile(profileName);
              }}
              disableRipple
            >
              <VisibilityIcon />
              {profileName}
            </MenuItem>
          ))}

        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`/objects/users/${ERP.LocalUser.ID()}`);
          }}
          disableRipple
        >
          <EditIcon />
          Edit Profiles
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
